<template>
  <div class="exam-main">
    <div class="stem" v-html="stem"></div>
    <ul>
      <li
        v-for="(option, index) in question.options"
        :class="['option', getResult(index)]"
        :key="option.id"
      >
        <span v-html="options[index]"></span>
        <div
          v-show="option_id === option.id"
          :class="isCorrect ? 'right-key' : 'wrong-key'"
        >
          <i :class="isCorrect ? 'el-icon-check' : 'el-icon-close'"></i
          >{{ isCorrect ? "选择正确" : "回答错误" }}
        </div>
        <div
          v-show="!isCorrect && correctIndex === index"
          class="correct-answer"
        >
          正确答案
        </div>
      </li>
      <li :class="['option', { error: !isSelect }]">
        <span> 我不会</span>
        <div class="wrong-key" v-show="!isSelect">
          <i class="el-icon-close"></i>
          回答错误
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import exam from "@/global/service/exam.js";
import "highlight.js/styles/zenburn.css";
var hljs = require("highlight.js/lib/core");
var md = require("markdown-it")({
  html: false,
  linkify: true,
  typographer: true,
  xhtmlOut: false,
  breaks: true,
  langPrefix: "language-",
  quotes: "“”‘’",
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return (
          '<pre class="hljs"><code>' +
          hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
          "</code></pre>"
        );
      } catch (e) {
        // console.log();
      }
    }

    return (
      '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
    );
  },
});
export default {
  props: ["currentId"],
  data() {
    return {
      paperId: "",
      question: "",
      questionId: "",
      stem: "",
      options: [],
      correctIndex: "",
      isCorrect: "",
      option_id: "",
      userChoice: {},
      isSelect: "",
    };
  },
  watch: {
    currentId: {
      handler: function (newVal) {
        this.currentId = newVal;
        this.getAnswer(this.currentId);
      },
    },
  },
  created() {
    this.paperId = this.$route.params.id;
    if (this.questionId == false) {
      this.getPaper();
    }
  },
  methods: {
    getPaper() {
      exam.getPaper(this.paperId).then((res) => {
        this.answers = res.data.answers;
        this.questionId = this.answers[0].id;
        this.getAnswer(this.questionId);
      });
    },
    getAnswer(questionId) {
      exam.getAnswer(this.paperId, questionId).then((res) => {
        this.question = res.data.question;
        this.stem = md.render(this.question.stem);
        this.isCorrect = res.data.is_correct == 1;
        this.isSelect = res.data.option_id !== null;
        this.option_id = res.data.option_id;
        this.userChoice = {};
        this.question.options.map((item, index) => {
          this.$set(this.options, index, md.render(item.content));
          if (item.correct == 1) {
            this.correctIndex = index;
          }
          if (this.option_id !== null && this.option_id == item.id) {
            this.userChoice[index] = this.isCorrect ? "correct" : "error";
          } else if (item.correct == 1) {
            this.userChoice[index] = "correct";
          }
        });
      });
    },
    getResult(index) {
      return this.userChoice[index];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/pre,
code {
  white-space: pre-wrap;
}
.exam-main {
  padding: 220px 20px 20px;
  height: 100%;
  position: relative;
  background: linear-gradient(transparent 200px, #fff 200px);
  .stem {
    width: 100%;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000;
    /deep/.hljs {
      display: inline-block;
      padding: 10px 5px 10px 10px;
      font-size: 16px;
      line-height: 18px;
      font-family: monaco, Consolas, Liberation Mono, Courier, monospace;
    }
    /deep/table,
    tr,
    td {
      border-collapse: collapse;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    .option {
      border: 1px solid #ebebeb;
      padding: 19px;
      margin: 10px 0;
      cursor: pointer;
      display: flex;
      span {
        display: inline-block;
        flex: 1;
        /deep/ p {
          margin: 0 !important;
        }
      }
      &.correct {
        background: #deffdd;
        border: 2px solid #34b230;
        padding: 18px;
      }
      &.error,
      &.unknown-unselected {
        background: #fdd;
        border: 2px solid #ff2e2e;
        padding: 18px;
      }
      .wrong-key {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 12px;
        font-weight: 600;
        color: #ff2e2e;
        .el-icon-close {
          margin-right: 5px;
        }
      }
      .right-key,
      .correct-answer {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 12px;
        font-weight: 600;
        color: #34b230;
        .el-icon-check {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
