import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes.js";
import cookies from "js-cookie";
import userService from "@/global/service/user";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.firstInit = false;
router.beforeEach(async (to, from, next) => {
  const TOKEN = cookies.get("web_token");
  if (TOKEN && !router.firstInit) {
    try {
      const userInfo = await userService.getUserInfo();
      this.$store.commit("USERINFO", userInfo.data);
      router.firstInit = true;
    } catch (e) {
      next();
    }
  } else {
    window.location.href = `https://account${process.env.VUE_APP_DOMAIN}?referer=${window.location.href}`;
  }
  next();
});
export default router;
