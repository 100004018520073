<template>
  <div class="stack">
    <div class="stack-header">
      <div class="stack-message">
        <img :src="stack.image_url" /><span>{{ stack.name }} 技能测评 </span>
      </div>
      <div class="left-message">
        <span>剩余 </span
        ><span class="num">{{ answers.length - currentIndex }}</span
        ><span> 个问题</span>
      </div>
    </div>
    <div class="stack-body">
      <el-progress
        :percentage="Number(progressPercentage)"
        color="#0fc700"
        :show-text="false"
        class="progress"
      ></el-progress>
      <div class="exam-container">
        <div class="exam-main">
          <div class="stem" v-html="stem"></div>
          <ul>
            <li
              v-for="(option, index) in question.options"
              :class="['option', { unselected: !isSelect }, getResult(index)]"
              :key="option.id"
              @click="selected(option, index)"
            >
              <span v-html="options[index]"></span>
              <div
                v-show="isSelect && optionIndex === index"
                :class="isCorrect ? 'right-key' : 'wrong-key'"
              >
                <i :class="isCorrect ? 'el-icon-check' : 'el-icon-close'"></i
                >{{ isCorrect ? "选择正确" : "回答错误" }}
              </div>
              <div
                v-show="
                  isSelect && optionIndex !== index && correctIndex == index
                "
                class="correct-answer"
              >
                正确答案
              </div>
            </li>
            <li
              :class="[
                'option',
                { unselected: !isSelect },
                { error: isSelect && unknowId === question.options.length },
              ]"
              @click="showResult(question.options.length)"
            >
              <span> 我不会</span>
              <div
                class="wrong-key"
                v-show="isSelect && unknowId === question.options.length"
              >
                <i class="el-icon-close"></i>
                回答错误
              </div>
            </li>
          </ul>
          <div class="count-bottom">
            <div
              class="doubt"
              :style="{ visibility: isSelect ? 'visible' : 'hidden' }"
            >
              <i class="el-icon-question"></i> 对题目有疑问？点击这里反馈
            </div>
            <el-button :disabled="!isSelect" @click="nextQ">
              <span>
                {{ answers.length - currentIndex !== 1 ? "下一题" : "提交"
                }}<i class="next-icon"
                  ><svg
                    t="1619101641957"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="18412"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M42.666667 512C42.666667 252.8 252.8 42.666667 512 42.666667s469.333333 210.133333 469.333333 469.333333-210.133333 469.333333-469.333333 469.333333S42.666667 771.2 42.666667 512z m85.333333 0a384 384 0 1 0 768 0 384 384 0 0 0-768 0z m170.666667 0a42.666667 42.666667 0 0 1 42.666666-42.666667h248.661334l-108.16-108.202666a42.666667 42.666667 0 0 1 60.330666-60.330667l181.034667 181.034667a42.666667 42.666667 0 0 1 0 60.330666l-0.554667 0.469334-180.48 180.565333a42.666667 42.666667 0 0 1-60.330666-60.330667L589.909333 554.666667 341.333333 554.666667a42.666667 42.666667 0 0 1-42.666666-42.666667z"
                      fill="#ffffff"
                      p-id="18413"
                    ></path></svg></i
              ></span>
            </el-button>
          </div>
        </div>
        <div class="count-down">
          <div class="count-down-tip">
            <i class="timer-icon">
              <svg
                t="1619147738490"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2157"
                width="16"
                height="16"
              >
                <path
                  d="M512 1024A515.413333 515.413333 0 0 1 0 512 515.413333 515.413333 0 0 1 512 0 515.413333 515.413333 0 0 1 1024 512 515.413333 515.413333 0 0 1 512 1024z m0-887.466667A378.88 378.88 0 0 0 136.533333 512 378.88 378.88 0 0 0 512 887.466667 378.88 378.88 0 0 0 887.466667 512 378.88 378.88 0 0 0 512 136.533333z"
                  p-id="2158"
                  fill="#"
                ></path>
                <path
                  d="M778.24 566.613333H443.733333v-341.333333h136.533334v204.8h197.973333v136.533333z"
                  p-id="2159"
                  fill="#34b230"
                ></path>
              </svg>
            </i>
            本题剩余时间
          </div>
          <el-progress
            type="circle"
            :width="90"
            :format="format"
            :show-text="true"
            :percentage="Number(timePercentage)"
            color="#34b230"
            :class="{ 'over-time': !Boolean(timePercentage) }"
          ></el-progress>
          <div class="count-down-text">
            <div class="tip">请及时作答</div>
            <div class="desc">未在剩余时间内作答 <br />该题将自动判断为错</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import exam from "@/global/service/exam.js";
import "highlight.js/styles/zenburn.css";
var hljs = require("highlight.js/lib/core");
var md = require("markdown-it")({
  html: false,
  linkify: true,
  typographer: true,
  xhtmlOut: false,
  breaks: true,
  langPrefix: "language-",
  quotes: "“”‘’",
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return (
          '<pre class="hljs"><code>' +
          hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
          "</code></pre>"
        );
      } catch (e) {
        // console.log();
      }
    }

    return (
      '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
    ); // use external default escaping
  },
});
export default {
  data() {
    return {
      paperId: "",
      stack: "",
      answers: [],
      currentIndex: 0,
      question: "",
      stem: "",
      options: [],
      buttonLoading: false,
      progressPercentage: 0,
      timePercentage: "",
      timeColors: [{ color: "#f56c6c", percentage: 0 }],
      userChoice: {},
      isSelect: "",
      optionIndex: "",
      isCorrect: "",
      correctIndex: "",
      timer: "",
      unknowId: "",
    };
  },
  computed: {
    ...mapState(["examStatus"]),
  },
  created() {
    this.paperId = this.$route.params.id;
    //在页面加载时读取localStorage里的状态信息
    localStorage.getItem("userMsg") &&
      this.$store.replaceState(
        Object.assign(
          this.$store.state,
          JSON.parse(localStorage.getItem("userMsg"))
        )
      );
    if (this.examStatus && this.paperId in this.examStatus) {
      this.currentIndex = this.examStatus[this.paperId].currentIndex;
    } else {
      this.currentIndex = 0;
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("userMsg", JSON.stringify(this.$store.state));
    });
    this.getPaper();
  },
  mounted() {
    this.decrease();
  },
  methods: {
    getPaper() {
      exam.getPaper(this.paperId).then((res) => {
        this.answers = res.data.answers;
        this.stack = res.data.stack;
        this.progressPercentage =
          this.currentIndex == 0
            ? 0
            : this.currentIndex * (100 / (this.answers.length - 1)).toFixed(2);
        this.getAnswer();
      });
    },
    getAnswer() {
      this.questionId = this.answers[this.currentIndex].id;
      exam.getAnswer(this.paperId, this.questionId).then((res) => {
        this.question = res.data.question;
        this.stem = md.render(this.question.stem);
        this.question.options.map((item, index) => {
          this.$set(this.options, index, md.render(item.content));
          this.userChoice[index] = "";
          if (item.correct == 1) {
            this.correctIndex = index;
          }
        });
        this.userChoice[this.question.options.length] = "";
        this.timePercentage = 100;
      });
    },
    getResult(index) {
      return this.userChoice[index];
    },
    selected(option, index) {
      if (this.isSelect == true) {
        return;
      }
      let paperId = this.paperId;
      let questionId = this.questionId;
      let option_id = option.id;
      this.optionIndex = index;
      this.isCorrect = option.correct == 1;
      this.showAnswer(index, option_id);
      exam.putSelect(paperId, questionId, { option_id: option_id });
    },
    showAnswer(index) {
      this.isSelect = true;
      clearInterval(this.timer);
      if (this.isCorrect) {
        this.userChoice[index] = "correct";
      } else {
        this.userChoice[index] = "error";
        this.userChoice[this.correctIndex] = "correct";
      }
    },
    showResult(index) {
      if (this.isSelect == true) {
        return;
      }
      this.optionIndex = index;
      this.unknowId = index;
      this.isCorrect = false;
      this.showAnswer(index);
    },
    nextQ() {
      if (this.currentIndex < this.answers.length - 1) {
        this.currentIndex += 1;
        this.decrease();
        this.getAnswer();
        let paperId = this.paperId;
        this.$store.commit({
          type: "EXAMSTATUS",
          [paperId]: { currentIndex: this.currentIndex },
        });
        this.progressPercentage += 100 / (this.answers.length - 1);
        this.progressPercentage.toFixed(2);
        this.isSelect = false;
        this.unknowId = "";
        this.userChoice = {};
      } else {
        this.$router.replace({ path: `/result/${this.paperId}` });
      }
    },
    format(percentage) {
      if (percentage == 100) {
        return 75;
      } else {
        return percentage !== 0 ? this.seconds : "0";
      }
    },
    decrease() {
      this.seconds = 75;
      let interval = 100 / 75;
      this.timer = setInterval(() => {
        if (this.seconds <= 0) {
          clearInterval(this.timer);
          this.seconds = 0;
          this.timePercentage = 0;
          if (!this.isSelect) {
            this.showResult(this.question.options.length);
          }
        } else {
          this.timePercentage -= interval;
          this.timePercentage.toFixed(2);
          if (this.timePercentage < 0) {
            this.timePercentage = 0;
          }
          this.seconds--;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/pre,
code {
  white-space: pre-wrap;
}
.stack {
  min-width: 1240px;
  padding-top: 90px;
  height: 100%;
  .stack-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .stack-message {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 7px;
      }
      span {
        font-size: 24px;
        font-weight: 600;
        color: #000;
        line-height: 33px;
      }
    }
    .left-message {
      color: #999;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 22px;
      .num {
        color: #0fc700;
      }
    }
  }
  .stack-body {
    margin-top: 20px;
    padding: 50px 80px;
    position: relative;
    background: #fff;
    .el-progress.progress {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
    }
    .exam-container {
      width: 100%;
      display: flex;
      .exam-main {
        flex: 1;
        width: calc(100% - 220px);
        .stem {
          width: 100%;
          margin-bottom: 20px;
          font-size: 30px;
          line-height: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000;
          /deep/ p {
            margin: 0 !important;
            word-break: break-all;
            word-wrap: break-word;
          }
          /deep/.hljs {
            display: inline-block;
            padding: 10px 5px 10px 10px;
            font-size: 16px;
            line-height: 18px;
            font-family: monaco, Consolas, Liberation Mono, Courier, monospace;
          }
          /deep/table,
          tr,
          td {
            border-collapse: collapse;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
        }
        ul {
          list-style-type: none;
          padding: 0;
          .option {
            border: 1px solid #ebebeb;
            padding: 19px;
            margin: 10px 0;
            cursor: pointer;
            display: flex;
            span {
              display: inline-block;
              flex: 1;
              /deep/.hljs {
                width: 100%;
                display: inline-block;
                code {
                  word-break: break-all;
                  word-wrap: break-word;
                }
              }
              /deep/ p {
                margin: 0 !important;
              }
            }
            &.unselected:hover {
              border-color: #0fc700;
            }
            &.correct {
              background: #deffdd;
              border: 2px solid #34b230;
              padding: 18px;
            }
            &.error,
            &.unknown-unselected {
              background: #fdd;
              border: 2px solid #ff2e2e;
              padding: 18px;
            }
            .wrong-key {
              font-family: PingFangSC-Semibold, PingFang SC;
              font-size: 12px;
              font-weight: 600;
              color: #ff2e2e;
              .el-icon-close {
                margin-right: 5px;
              }
            }
            .right-key,
            .correct-answer {
              font-family: PingFangSC-Semibold, PingFang SC;
              font-size: 12px;
              font-weight: 600;
              color: #34b230;
              .el-icon-check {
                margin-right: 5px;
              }
            }
          }
        }
        .count-bottom {
          margin-top: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .doubt {
            font-size: 16px;
            line-height: 22px;
            color: #999;
            font-family: PingFangSC-Regular\, PingFang SC;
            font-weight: 400;
            cursor: pointer;
            i {
              display: inline-block;
              color: #34b230;
              font-size: 1.2rem;
              vertical-align: baseline;
            }
          }
          .el-button {
            border-radius: 0;
            background: #0fc700;
            border: none;
            width: 150px;
            height: 40px;
            padding: 0;
            color: #fff;
            .next-icon {
              display: inline-block;
              vertical-align: text-top;
              margin-left: 5px;
            }
          }
        }
      }
      .count-down {
        margin-left: 100px;
        width: 120px;
        .count-down-tip {
          display: inline-block;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #999;
          line-height: 22px;
          .timer-icon {
            display: inline-block;
            width: 16px;
            height: 22px;
            vertical-align: middle;
          }
        }
        /deep/.el-progress {
          display: block;
          width: 90px;
          margin: 20px auto;
          .el-progress__text {
            font-size: 30px !important;
            font-family: HelveticaNeue-Medium, HelveticaNeue;
            color: #34b230;
          }
          &.over-time {
            .el-progress__text {
              color: #f56c6c;
            }
          }
        }
        .count-down-text {
          text-align: center;
          font-size: 12px;
          .tip {
            line-height: 17px;
          }
          .desc {
            color: #999;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
