import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
function storeLocalStore(state) {
  window.localStorage.setItem("userMsg", JSON.stringify(state));
}
export default new Vuex.Store({
  state: {
    themeColor: "light",
    userInfo: {},
    examStatus: null,
  },
  mutations: {
    THEME(state, theme) {
      state.themeColor = theme;
    },
    USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    EXAMSTATUS(state, examStatus) {
      // if (localStorage.getItem("userMsg").examStatus) {
      // state.examStatus = Vue.set(
      //   state.examStatus, Object.keys("examStatus"), examStatus);
      // } else {
      state.examStatus = Object.assign({}, examStatus);
      // }
      storeLocalStore(state);
    },
  },
  actions: {},
  modules: {},
});
