<template>
  <div class="result-page">
    <exam-header></exam-header>
    <div class="reslut-list">
      <div class="stack-header">
        <div class="stack-message">
          <img :src="stack.image_url" /><span>{{ stack.name }} 技能测评 </span>
        </div>
        <div class="left-message">
          <span>正确率 </span><span class="num">{{ correctRate }}%</span>
        </div>
      </div>
      <ul>
        <li
          v-for="(item, index) in answers"
          :key="item.id"
          @click="showQ(item.id)"
        >
          <i
            :class="[
              item.is_correct == 2 || item.is_correct == null
                ? 'el-icon-circle-close'
                : 'el-icon-circle-check',
            ]"
          ></i>
          <span>{{ index + 1 }}</span>
        </li>
      </ul>
    </div>
    <question-card :currentId="questionId" />
  </div>
</template>

<script>
import exam from "@/global/service/exam.js";
import examHeader from "./examHeader.vue";
import QuestionCard from "./questionCard.vue";
export default {
  components: { examHeader, QuestionCard },
  data() {
    return {
      paperId: "",
      answers: "",
      question: "",
      questionId: "",
      stack: "",
      currentId: "",
      rightNum: 0,
      isCorrect: false,
      correctRate: 0,
    };
  },
  created() {
    this.paperId = this.$route.params.id;
    this.currentIndex = 0;
    this.getPaper();
  },
  methods: {
    getPaper() {
      exam.getPaper(this.paperId).then((res) => {
        this.answers = res.data.answers;
        this.stack = res.data.stack;
        let rightNum = 0;
        this.answers.map((item) => {
          rightNum = item.is_correct == 1 ? (rightNum += 1) : rightNum;
        });
        this.correctRate = ((rightNum / this.answers.length) * 100).toFixed(2);
        this.getAnswer();
      });
    },
    getAnswer() {
      this.questionId = this.answers[this.currentIndex].id;
      exam.getAnswer(this.paperId, this.questionId).then((res) => {
        this.question = res.data.question;
      });
    },
    showQ(item_id) {
      this.questionId = item_id;
    },
  },
};
</script>

<style lang="less" scoped>
.result-page {
  width: 100%;
  min-height: 100%;
  padding: 0 calc(50% - 620px);
  background: #f5f5f5;
  .reslut-list {
    position: fixed;
    min-width: 1240px;
    background: #f5f5f5;
    top: 70px;
    padding-top: 20px;
    z-index: 1;
    .stack-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .stack-message {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 7px;
        }
        span {
          font-size: 24px;
          font-weight: 600;
          color: #000;
          line-height: 33px;
        }
      }
      .left-message {
        color: #999;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 22px;
        .num {
          color: #0fc700;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      li {
        display: flex;
        flex: 1;
        padding-top: 5px;
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        &:hover {
          background: #fff;
        }
        i[class*="el-icon"] {
          margin-bottom: 3px;
        }
        .el-icon-circle-close {
          color: #ff2e2e;
        }
        .el-icon-circle-check {
          color: #34b230;
        }
      }
    }
  }
}
</style>
