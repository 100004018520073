<template>
  <div class="plus-platform-section">
    <div class="page-new-layout">
      <layout-header :menuWidth.sync="menuWidth" :theme.sync="theme">
        <template v-slot:title>
          {{ course.name }}
        </template>
        {{ currentSection.name }}
      </layout-header>
      <div :class="['page-body', theme]">
        <div class="page-body-main">
          <video
            ref="videoPlayer"
            id="videoPlayer"
            class="lesson-play video-js vjs-big-play-centered"
          ></video>
        </div>
        <div class="page-body-aside fade" :style="{ width: menuWidth + 'px' }">
          <ul :class="theme">
            <li
              class="chapter-list"
              v-for="chapter in chapters"
              :key="chapter.id"
            >
              <div class="collapse-icon" @click="toggleMenu(chapter.id)">
                <span>{{ chapter.name }}</span>
                <i
                  :class="
                    menuStatus[chapter.id]
                      ? 'el-icon-arrow-up'
                      : 'el-icon-arrow-down'
                  "
                />
              </div>
              <ul class="fade" v-show="menuStatus[chapter.id]">
                <li
                  v-for="lesson in chapter.lessons"
                  :key="lesson.id"
                  :class="[
                    'section-list',
                    { current: currentId == lesson.id ? true : false },
                  ]"
                  @click="changesection(lesson.id, chapter.id)"
                >
                  <i class="el-icon-video-play"></i>
                  {{ lesson.name }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from "@/components/Layout/LayoutHeader.vue";
import "video.js/dist/video-js.min.css";
import videojs from "video.js/dist/video.min";
import "@/style/widget/videoJs.css";
import { mapState } from "vuex";
import course from "../../global/service/course.js";
export default {
  name: "Learn",
  data() {
    return {
      seriesId: "",
      courseId: "",
      sectionId: "",
      course: "",
      chapters: "",
      currentId: "",
      currentSection: "",
      isMenuClose: false,
      isLight: true,
      menuWidth: 240,
      theme: "",
      menuStatus: {},
      last_play_position: "",
      playTime: null,
      endTime: null,
    };
  },
  components: {
    LayoutHeader,
  },
  mounted() {
    onbeforeunload = () => {
      this.outputTime();
    };
  },
  computed: {
    ...mapState(["themeColor"]),
  },
  created() {
    this.theme = this.themeColor;
    this.setId();
  },
  watch: {
    $route() {
      this.setId();
      if (!this.currentSection.url) {
        this.$message.error("视频地址无效！");
        return;
      }
    },
  },
  methods: {
    toggleTheme() {
      this.isLight = !this.isLight;
      this.theme = this.theme == "light" ? "dark" : "light";
      this.$store.commit("THEME", this.theme);
    },
    toggleAside() {
      this.menuWidth = this.menuWidth == 240 ? 0 : 240;
      this.$refs.pageAside.setAttribute("style", `width:${this.menuWidth}px`);
    },
    menuListShow() {
      this.chapters.forEach((chapter) => {
        this.$set(this.menuStatus, `${chapter.id}`, false);
      });
    },
    sectionInfo() {
      let chapter = this.chapters.find((course) => {
        return course.id == this.courseId;
      });
      let lesson = chapter.lessons.find((lesson) => {
        return lesson.id == this.sectionId;
      });
      this.currentSection = lesson;
      this.currentId = lesson.id;
      this.menuStatus[chapter.id] = true;
    },
    setId() {
      const { seriesId, courseId, sectionId } = this.$route.params;
      this.seriesId = seriesId;
      if (courseId && sectionId) {
        this.courseId = courseId;
        this.sectionId = sectionId;
        this.getData();
      } else {
        this.getReset();
      }
    },
    getReset() {
      course.courseId(this.seriesId).then((res) => {
        this.course = res.data;
        this.chapters = res.data.courses;
        this.courseId = this.chapters[0].id;
        this.sectionId = this.chapters[0].lessons[0].id;
        this.$router.replace(
          `${this.seriesId}/${this.courseId}/${this.sectionId}`
        );
        this.sectionInfo();
      });
    },
    getData() {
      course.courseId(this.seriesId).then((res) => {
        this.course = res.data;
        this.chapters = res.data.courses;
        this.menuListShow();
        this.sectionInfo();
        this.getLatestPlay();
      });
    },
    getLatestPlay() {
      course
        .getRecord({
          seriesid: this.seriesId,
          courseId: this.courseId,
          lessonId: this.sectionId,
        })
        .then((res) => {
          this.last_play_position = res.data ? res.data.last_play_position : 0;
          this.initVideo();
        });
    },
    initVideo() {
      //初始化视频
      const videoOption = {
        controls: true,
        autoplay: true,
        preload: "auto",
        playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          // 设置控制条组件
          /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
          children: [
            { name: "playToggle" }, // 播放按钮
            { name: "currentTimeDisplay" }, // 当前已播放时间
            { name: "progressControl" }, // 播放进度条
            { name: "durationDisplay" }, // 总时间
            {
              // 倍数播放
              name: "playbackRateMenuButton",
              playbackRates: [0.5, 1, 1.5, 2, 2.5],
            },
            {
              name: "volumePanel", // 音量控制
              inline: false, // 不使用水平方式
            },
            { name: "pictureInPictureToggle" }, //画中画
            { name: "FullscreenToggle" }, // 全屏
          ],
        },
      };
      if (!this.videoInstance) {
        this.videoInstance = videojs(this.$refs.videoPlayer, videoOption);
      }

      this.videoInstance.src(this.currentSection.url);
      this.videoInstance.ready(() => {
        this.videoInstance.currentTime(this.last_play_position);
      });
      this.videoEvent();
    },
    videoEvent() {
      this.videoInstance.on("play", () => {
        this.playTime = new Date();
      });
      this.videoInstance.on("pause", () => {
        this.outputTime();
      });
    },
    outputTime() {
      let second_duration;
      if (this.playTime) {
        this.endTime = new Date();
        second_duration = this.endTime - this.playTime;
        this.playTime = null;
        this.endTime = null;
      } else {
        second_duration = 0;
      }
      this.videoInstance.ready(() => {
        let last_play_position = this.videoInstance.currentTime();
        let params = { second_duration, last_play_position };
        course.setRecord(
          {
            seriesid: this.seriesId,
            courseId: this.courseId,
            lessonId: this.sectionId,
          },
          params
        );
      });
    },
    changesection(sectionId, courseId) {
      this.courseId = courseId;
      this.sectionId = sectionId;
      this.$router.push({
        params: { courseId: courseId, sectionId: sectionId },
      });
    },
    toggleMenu(courseId) {
      this.menuStatus[courseId] = !this.menuStatus[courseId];
    },
  },
};
</script>
<style lang="less" scoped>
@green: #0fc700;
.page-new-layout {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  .page-header {
    &.dark {
      background: rgb(47, 49, 51);
      box-shadow: rgb(0 0 0 / 50%) 0px 4px 10px 0px;
      .header-middle-lesson {
        background: rgb(41, 38, 38);
      }
    }
    &.light {
      background: rgb(238, 238, 238);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
      .header-middle-lesson {
        background: #fff;
      }
    }
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    font-size: 16px;
    font-weight: 400;
    font-family: PingFangSC-Medium, PingFang SC;
    .header-left-title {
      margin-left: 5px;
    }
    .header-middle-lesson {
      display: inline-block;
      text-align: center;
      line-height: 40px;
      color: @green;
      padding: 0 20px;
      white-space: nowrap;
      border-radius: 20px;
    }
    .header-right {
      cursor: pointer;
      display: inline-block;
      display: flex;
      align-items: center;
      .mode-icon {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        height: 22px;
        line-height: 22px;
        img {
          vertical-align: text-bottom;
        }
        span {
          margin: 0 20px 0 5px;
        }
      }
      .img-close {
        width: 22px;
        cursor: pointer;
        img {
          max-width: 100%;
        }
      }
    }
  }
  .page-body {
    display: flex;
    justify-content: center;
    width: 100%;
    &.light {
      background: rgb(247, 247, 247);
    }
    &.dark {
      background: rgb(20, 19, 19);
    }
    height: calc(100vh - 72px);
    max-height: calc(100vh - 72px);
    .page-body-main {
      flex: 1;
      background: #000;
      .lesson-play {
        outline: none;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .page-body-aside {
      width: 240px;
      height: 100%;
      overflow-y: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .light {
        color: #333;
      }
      .light,
      .dark {
        padding: 20px 0 0 20px;
        .chapter-list {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 20px;
          cursor: default;
          .collapse-icon {
            position: relative;
            padding-right: 20px;
            cursor: pointer;
            .el-icon-arrow-down,
            .el-icon-arrow-up {
              display: inline-block;
              font-size: 10px;
              position: absolute;
              width: 10px;
              height: 10px;
              top: 8px;
              right: 8px;
            }
          }
          ul {
            padding-left: 15px;
            .section-list {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              cursor: pointer;
              margin-right: 10px;
              margin-top: 10px;
              &.current {
                color: @green;
              }
            }
          }
          .fade-enter-active,
          .fade-leave-active {
            transition: opacity 0.5s;
          }
          .fade-enter,
          .fade-leave-to {
            opacity: 0;
          }
        }
      }
    }
  }
}
.light {
  color: #161718;
}
.dark {
  color: #fff;
}
ul {
  list-style: none;
}
</style>
