import request from "../request/axios.js";
import API from "../request/api.js";

const coursePlay = {
  courseId: (seriesId) => {
    return request.get(`${API.courseId}/${seriesId}`);
  },
  getRecord: ({ seriesid, courseId, lessonId }) => {
    return request.get(
      `${API.videoReord}/${seriesid}/course/${courseId}/lesson/${lessonId}/record`
    );
  },
  setRecord: ({ seriesid, courseId, lessonId }, params) => {
    return request.post(
      `${API.videoReord}/${seriesid}/course/${courseId}/lesson/${lessonId}/record`,
      params
    );
  },
};

export default coursePlay;
