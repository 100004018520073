<template>
  <div class="exam-page">
    <exam-header></exam-header>
    <exam-card />
  </div>
</template>

<script>
import examHeader from "./widgets/examHeader.vue";
import ExamCard from "./widgets/examCard.vue";
export default {
  components: { examHeader, ExamCard },
};
</script>

<style lang="less" scoped>
.exam-page {
  width: 100%;
  min-height: 100%;
  padding: 0 calc(50% - 620px);
  background: #f5f5f5;
}
</style>
