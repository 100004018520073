import request from "../request/axios.js";
import API from "../request/api.js";

const Project = {
  projectInfo: (itemId) => {
    return request.get(`${API.projectsLearn}/${itemId}`);
  },
};

export default Project;
