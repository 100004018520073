const API_PREFIX = "https://api" + process.env.VUE_APP_DOMAIN;
export default {
  smsCode: `${API_PREFIX}/api/web/sms/code`,
  smsRegister: `${API_PREFIX}/api/web/sms/register`,
  courseId: `${API_PREFIX}/api/web/course/series`,
  userInfo: `${API_PREFIX}/api/web/users/user-info`,
  projectsLearn: `${API_PREFIX}/api/web/projects`,
  videoReord: `${API_PREFIX}/api/web/course/series`,
  paperDetail: `${API_PREFIX}/api/web/question/exam`,
};
