import Learn from "../views/Learn/Learn.vue";
import Projects from "../views/Projects/Projects.vue";
import Exam from "../views/Exam/Exam.vue";
import examResult from "../views/Exam/widgets/examResult.vue";
export default [
  {
    path: "/course/:seriesId/:courseId/:sectionId",
    component: Learn,
  },
  {
    path: "/course/:seriesId",
    component: Learn,
  },
  {
    path: "/project/:itemId",
    component: Projects,
  },
  {
    path: "/exam/:id",
    component: Exam,
  },
  {
    path: "/result/:id",
    component: examResult,
  },
];
