<template>
  <div class="project-section">
    <div class="page-new-layout">
      <layout-header :menuWidth.sync="menuWidth" :theme.sync="theme">
        <template v-slot:title>
          {{ project.name }}
        </template>
        {{ itemContent.name }}
      </layout-header>
      <div :class="['page-body', theme]">
        <div class="page-body-main" v-html="itemContent"></div>
        <div
          class="page-body-aside fade"
          :style="{ width: menuWidth + 'px' }"
          ref="pageAside"
        >
          <ul :class="theme">
            <li
              class="chapter-list"
              v-for="(item, index) in pages"
              :key="item.id"
              @click="changeChapter(index)"
            >
              <div class="collapse-icon">
                <span>{{ item.name }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from "@/components/Layout/LayoutHeader.vue";
import Project from "@/global/service/projects.js";
import "@/style/widget/typora-vue-theme-master/vue.css";
import MarkdownIt from "@jiker/markdown-it";
export default {
  name: "Projects",
  data() {
    return {
      isLight: true,
      itemId: "",
      project: "",
      pages: "",
      itemContent: "",
      theme: "light",
      menuWidth: 240,
      currentIndex: 0,
    };
  },
  components: {
    LayoutHeader,
  },
  computed: {},
  created() {
    this.itemId = this.$route.params.itemId;
    this.getItemInfo();
  },
  methods: {
    getItemInfo() {
      Project.projectInfo(this.itemId).then((res) => {
        this.pages = res.data.pages;
        this.project = res.data;
        this.showContent();
      });
    },
    showContent() {
      this.itemContent = MarkdownIt(
        this.pages[this.currentIndex].contents[0].content.html
      );
    },
    changeChapter(index) {
      this.currentIndex = index;
      this.showContent();
    },
  },
};
</script>

<style lang="less" scoped>
@green: #0fc700;
.project-section {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  .page-header {
    &.dark {
      background: rgb(47, 49, 51);
      box-shadow: rgb(0 0 0 / 50%) 0px 4px 10px 0px;
      .header-middle-lesson {
        background: rgb(41, 38, 38);
      }
    }
    &.light {
      background: rgb(238, 238, 238);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
      .header-middle-lesson {
        background: #fff;
      }
    }
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    font-size: 16px;
    font-weight: 400;
    font-family: PingFangSC-Medium, PingFang SC;
    .header-left-title {
      margin-left: 5px;
    }
    .header-middle-lesson {
      display: inline-block;
      text-align: center;
      line-height: 40px;
      color: @green;
      padding: 0 20px;
      white-space: nowrap;
      border-radius: 20px;
    }
    .header-right {
      cursor: pointer;
      display: inline-block;
      display: flex;
      align-items: center;
      .mode-icon {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        height: 22px;
        line-height: 22px;
        img {
          vertical-align: text-bottom;
        }
        span {
          margin: 0 20px 0 5px;
        }
      }
      .img-close {
        width: 22px;
        cursor: pointer;
        img {
          max-width: 100%;
        }
      }
    }
  }
  .page-body {
    display: flex;
    flex: 1;
    width: 100%;
    height: calc(100vh - 72px);
    max-height: calc(100vh - 72px);
    justify-content: center;
    padding: 20px 50px;
    &.light {
      background: rgb(247, 247, 247);
    }
    &.dark {
      background: rgb(20, 19, 19);
      table,
      tr,
      th,
      td {
        background: rgb(20, 19, 19) !important;
      }
    }
    .page-body-main {
      width: calc(100vw- 240px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      flex: 1;
      img,
      video {
        outline: none;
        text-align: center;
      }
      video {
        height: 100%;
      }
    }
    .page-body-aside {
      width: 240px;
      flex: none;
      height: 100%;
      overflow-y: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .light {
        color: #333;
      }
      .light,
      .dark {
        padding: 20px 0 0 20px;
        .chapter-list {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 20px;
          cursor: default;
          .collapse-icon {
            position: relative;
            padding-right: 20px;
            cursor: pointer;
            .el-icon-arrow-down,
            .el-icon-arrow-up {
              display: inline-block;
              font-size: 10px;
              position: absolute;
              width: 10px;
              height: 10px;
              top: 8px;
              right: 8px;
            }
          }
          ul {
            padding-left: 15px;
            .section-list {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              cursor: pointer;
              margin-right: 10px;
              margin-top: 10px;
              &.current {
                color: @green;
              }
            }
          }
          .fade-enter-active,
          .fade-leave-active {
            transition: opacity 0.5s;
          }
          .fade-enter,
          .fade-leave-to {
            opacity: 0;
          }
        }
      }
    }
  }
}
.light {
  color: #161718;
}
.dark {
  color: #fff;
}
ul {
  list-style: none;
}
</style>
