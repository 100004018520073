<template>
  <div :class="['page-header', theme]">
    <span class="header-left-title"><slot name="title"></slot></span>
    <span class="header-middle-lesson"><slot></slot></span>
    <div class="header-right">
      <div class="mode-icon" @click="toggleTheme">
        <img
          :src="
            isLight
              ? require('@/assets/images/icon/themeIcon.png')
              : require('@/assets/images/icon/themeIconDark.png')
          "
        />
        <span>{{ isLight ? "深色模式" : "浅色模式" }}</span>
      </div>
      <div class="img-close">
        <img
          @click="toggleAside"
          :src="
            isLight
              ? require('@/assets/images/icon/menuIcon.png')
              : require('@/assets/images/icon/menuIconDark.png')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutHeader",
  props: ["menuWidth", "theme"],
  data() {
    return {
      isLight: true,
    };
  },
  methods: {
    toggleTheme() {
      this.isLight = !this.isLight;
      let theme = this.theme == "light" ? "dark" : "light";
      this.$store.commit("THEME", theme);
      this.$emit("update:theme", theme);
    },
    toggleAside() {
      let menuWidth = this.menuWidth == 240 ? 0 : 240;
      this.$emit("update:menuWidth", menuWidth);
    },
  },
};
</script>

<style lang="less" scoped>
@green: #0fc700;
.page-header {
  &.dark {
    background: rgb(47, 49, 51);
    box-shadow: rgb(0 0 0 / 50%) 0px 4px 10px 0px;
    .header-middle-lesson {
      background: rgb(41, 38, 38);
    }
  }
  &.light {
    background: rgb(238, 238, 238);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
    .header-middle-lesson {
      background: #fff;
    }
  }
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  font-size: 16px;
  font-weight: 400;
  font-family: PingFangSC-Medium, PingFang SC;
  .header-left-title {
    margin-left: 5px;
  }
  .header-middle-lesson {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    color: @green;
    padding: 0 20px;
    white-space: nowrap;
    border-radius: 20px;
  }
  .header-right {
    cursor: pointer;
    display: inline-block;
    display: flex;
    align-items: center;
    .mode-icon {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      img {
        vertical-align: text-bottom;
      }
      span {
        margin: 0 20px 0 5px;
      }
    }
    .img-close {
      width: 22px;
      cursor: pointer;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
