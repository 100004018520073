import request from "../request/axios.js";
import API from "../request/api.js";

const exam = {
  getPaper: (id) => {
    return request.get(`${API.paperDetail}/${id}`);
  },
  getAnswer: (id, answerId) => {
    return request.get(`${API.paperDetail}/${id}/${answerId}`);
  },
  putSelect: (id, answerId, { option_id }) => {
    return request.put(`${API.paperDetail}/${id}/${answerId}`, { option_id });
  },
};

export default exam;
