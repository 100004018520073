<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/images/logo/aitschool_logo.png" />
    </div>
    <div class="optOut" @click="logout">
      退出测评<i class="el-icon-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="less">
.header {
  width: 1240px;
  position: fixed;
  top: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  z-index: 1;
  .logo {
    background: rgb(255, 255, 255);
    width: 140px;
    padding: 5px 10px;
    height: 70px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0 0 8px 8px;
    text-align: center;
    line-height: 70px;
    img {
      max-width: 100%;
    }
  }
  .optOut {
    font-weight: 400;
    color: #999;
    line-height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
      margin-left: 7px;
    }
  }
}
</style>
